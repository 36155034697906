<script context="module" lang="ts">
	export declare namespace ImageIconNamespace {
		type Props = {
			size?: number;
			src: string | [string, string] | [string, string, string]; // Image source
			rotate?: number;
			absolute?: boolean;
			alt?: string;
		};
	}
</script>

<script lang="ts">
	import { styles } from '$lib/utilities/core/styles';
	import classNames from 'classnames';
	import _ from 'lodash';

	const { size, src, rotate, absolute, alt }: ImageIconNamespace.Props = $props();

	const classes = $derived(
		classNames('ImageIcon', {
			[`ImageIcon--rotate-${rotate}`]: rotate,
			[`ImageIcon--alt-${alt}`]: alt,
			'ImageIcon--absolute': absolute,
		}),
	);

	const multiIconClasses = $derived(
		classNames('ImageIconMultiIcon', {
			[`ImageIconMultiIcon--length-${src?.length}`]: _.isArray(src),
		}),
	);

	const styling = $derived(
		styles({
			'--ImageIcon--size': size,
		}),
	);
</script>

{#if _.isArray(src)}
	<div class={multiIconClasses}>
		{#each src as srcItem}
			<!-- content here -->
			<img src={srcItem} {alt} width="1rem" height="1rem" class={classes} style={styling} />
		{/each}
	</div>
{:else}
	<img {src} {alt} width="1rem" height="1rem" class={classes} style={styling} />
{/if}

<style lang="scss">
	@import '$lib/styles/colours.scss';
	.ImageIcon {
		--ImageIcon--size: 1;
		display: inline-flex;
		transition: all 0.2s var(--easing);
		width: calc(var(--padding) * var(--ImageIcon--size));
		height: calc(var(--padding) * var(--ImageIcon--size));

		&--absolute {
			position: absolute;
		}
	}

	.ImageIconMultiIcon {
		display: flex;

		&--length-2 {
			$scale: 0.9;
			$offset: 20%;
			$offsetHalf: calc($offset / 2);

			.ImageIcon {
				&:nth-child(2) {
					position: absolute;
				}

				&:nth-child(1) {
					transform: translate(-$offset, -$offsetHalf) scale($scale);
				}

				&:nth-child(2) {
					transform: translate($offset, $offsetHalf) scale($scale);
				}
			}
		}

		&--length-3 {
			$scale: 0.8;
			$offset: 25%;
			$offsetHalf: calc($offset / 2);

			.ImageIcon {
				&:nth-child(1),
				&:nth-child(3) {
					position: absolute;
				}

				&:nth-child(1) {
					transform: translate(-$offset, -$offsetHalf) scale($scale);
				}

				&:nth-child(2) {
					z-index: 1;
					transform: translate(0, $offsetHalf) scale($scale);
				}

				&:nth-child(3) {
					transform: translate($offset, -$offsetHalf) scale($scale);
				}
			}
		}
	}
</style>
